/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <GridContainer className="justify-content-center mt-5 mt-0im">
          <GridItem xs={12} sm={12} md={12} lg={4} className="text-left">
            <div className="footerContent">

              <p>In order to make an informed choice of providers and gaming content for a casino website, it is recommended to research the targeted market. To do that, one must define what are its popular game genres, providers, sports, and gaming titles</p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={2} className="text-left">
            <h4 className="text-left">Game Marketplace</h4>
            <List className={classes.list + " footer_links fooytere_link"}>
              <ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>Explore</a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>Activity</a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>Privacy Policy</a>
              </ListItem>

            </List>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={2} className="text-left">
            <h4 className="text-left text_cerbny">Explore</h4>
            <List className={classes.list + " footer_links fooytere_link"}>
              <ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>DeFi</a>
              </ListItem>

            </List>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={2} className="text-left">
            <h4 className="text-left text_cerbny">Community</h4>
            <List className={classes.list + " footer_links fooytere_link"}>
              <ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>Our Blog</a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>Help Center</a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>Contacts</a>
              </ListItem>
            </List>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={2} className="text-left">
            <div className="text_cerbny">
              <a href="#"><img src={require("../../assets/images/logo1.jpeg")} alt="" className="img-fluid" /></a>
            </div>
          </GridItem>
        </GridContainer>
        <div className="subfooter_18">
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={5} className="text-left">
              <p className="copyright_txt">© Copyright ©2024 All rights reserved</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={7} className="text-left">
              <ul className="social_links">
                <li><a href="#" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
                <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                {/* <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li> */}
                <li><a href="#" target="_blank"><i className="fab fa-medium-m"></i></a></li>
              </ul>
            </GridItem>
          </GridContainer>
        </div>
      </div>

    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
